<template lang="html">
  <div class="about">
    <div class="text_line">
      <div class="lined_text">
        <h1 class="text">Über mich</h1>
        <div class="long" />
      </div>
    </div>
    <main v-if="!this.loading">
      <div v-for="element in data" :key="element._id">
        <div class="img-text" v-if="element.type == 'Bild mit Text'">
          <div class="img">
            <img :src="element.image.path" />
            <p>{{ element.image_caption }}</p>
          </div>
          <div class="text">
            <div class="headline">
              <h2>{{ element.headline }}</h2>
              <p>{{ element.subheadline }}</p>
            </div>
            <p v-html="element.text" />
          </div>
        </div>
        <div class="image" v-if="element.type == 'Bild'">
          <div class="headline">
            <h2>{{ element.headline }}</h2>
            <p>{{ element.subheadline }}</p>
          </div>
          <img :src="element.image.path" />
          <p class="image_caption">{{ element.image_caption }}</p>
        </div>
      </div>

      <div class="non-profit" v-if="nonProfitProjects.length > 0">
        <div class="headline">
          <h2>Non Profit / Free Works</h2>
          <p>Peeling für die Seele</p>
        </div>
        <div class="non-profit-elements">
          <div
            class="element"
            v-for="project in nonProfitProjects"
            :key="project._id"
          >
            <img :src="`http://kreps-grafik.de${project.image.path}`" alt="" />
            <p>
              <strong>{{ project.customer }} _ </strong
              >{{ project.description }}
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import CMSService from "../CMS-Service.js";
export default {
  data() {
    return {
      nonProfitProjects: new Array(),
      data: [],
      loading: true,
    };
  },
  created() {
    CMSService.getCollectionEntries("nonProfit").then((data) => {
      this.nonProfitProjects = data.data.entries;
    });
    CMSService.getCollectionEntries("UeberMich").then((data) => {
      this.data = data.data.entries;
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../main.scss";

.about {
  max-width: 1250px;
  margin: auto;
  padding: 0 10%;

  .text_line {
    margin-bottom: 156px;
  }

  .long {
    border-bottom: 2px solid #d9000d;
    margin-bottom: 18px;
  }
  .text_line {
    max-width: 450px;
    margin-top: 30px;
  }

  .img-text {
    margin-bottom: 160px;
    display: flex;

    .img {
      width: 33%;
      img {
        width: 100%;
      }
      p {
        font-size: 16px;
      }
    }

    .text {
      padding-left: 35px;
      width: 66%;

      .headline {
        display: flex;
        padding-bottom: 8px;

        h2 {
          text-decoration: underline;
          text-decoration-color: #d9000d;
          font-weight: 400;
        }
        p {
          margin-left: 10px;
          line-height: 55px;
        }
      }
      p {
        font-size: 20px;
      }
    }
  }

  .image {
    margin-bottom: 160px;
    img {
      position: relative;
      width: 100%;
      max-width: 700px;
      left: 50%;
      transform: translatex(-50%);
    }
    .image_caption {
      position: relative;
      font-size: 16px;
      width: 100%;
      max-width: 700px;
      left: 50%;
      transform: translatex(-50%);
    }
    .headline {
      display: flex;
      padding-bottom: 45px;

      h2 {
        text-decoration: underline;
        text-decoration-color: #d9000d;
        font-weight: 400;
      }
      p {
        margin-left: 10px;
        line-height: 55px;
      }
    }
    width: 100%;
  }

  .non-profit {
    .headline {
      display: flex;

      h2 {
        text-decoration: underline;
        text-decoration-color: #d9000d;
        font-weight: 400;
      }
      p {
        margin-left: 10px;
        line-height: 55px;
      }
    }

    .non-profit-elements {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 32px 0;
      .element {
        width: 25vw;
        min-width: 310px;
        max-width: 400px;

        margin: 19px 19px;

        img {
          width: 100%;
        }
      }
    }
  }
}

//mobile
@media screen and (max-width: 720px) {
  .text_line {
    margin-bottom: 36px !important;
  }

  .img-text {
    margin-bottom: 42px !important;
    position: relative;
    flex-direction: column;

    .img {
      padding-top: 80px;
      width: 100% !important;
      margin-bottom: 26px;
    }

    .text {
      padding-left: 0px !important;
      width: 100% !important;

      .headline {
        position: absolute;
        top: 0px;
        flex-direction: column;

        h2 {
          font-size: 24px;
        }
        p {
          margin-left: 0px !important;
          line-height: 32px !important;
        }
      }
    }
  }
  .image {
    margin-bottom: 42px !important;
    .headline {
      padding-bottom: 16px !important;
      flex-direction: column;

      h2 {
        font-size: 24px;
      }
      p {
        margin-left: 0px !important;
        line-height: 32px !important;
      }
    }
  }
  .non-profit {
    .headline {
      flex-direction: column;
      h2 {
        font-size: 24px;
      }
      p {
        margin-left: 0px !important;
        line-height: 32px !important;
      }
    }
  }
  .non-profit-elements {
    padding: 16px 0 !important;
  }
}
</style>
